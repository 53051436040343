import { WORKSPACE, FILES_HOST_PROTOCOL, FILES_DOMAIN_NAME } from 'config';
import { setItem, getItem } from 'lib/storage';

// Match all URLs in a string
const urlRE = /(https?:\/\/[^\s]+)/gi;

export function getURL({ query, onMissingURL }) {
  // Web page URL
  let { url } = query;
  // text: Share target API (See: web manifest)
  // v: YouTube video ID
  // list: YouTube playlist ID
  const { text, v, list } = query;

  // Shared URLs will be found in the text query argument
  if (text && !url) {
    const matches = text.match(urlRE);

    if (matches) {
      // We want the last URL
      // The text might contain multiple URLs
      url = matches.pop();
    } else {
      // No URLs were found in the shared content
      onMissingURL?.();
    }
  }
  // YouTube video ID
  else if (v) {
    // https://www.youtube.com/watch?v=VIDEO_ID
    url = `https://www.youtube.com/watch?v=${v}`;

    // https://www.youtube.com/watch?v=VIDEO_ID&list=LIST_ID
    if (list) {
      url = `${url}&list=${list}`;
    }
  }
  // YouTube playlists
  else if (list) {
    url = `https://www.youtube.com/playlist?list=${list}`;
  }

  return url ?? '';
}

// Match Youtube IDs
const idRE = /^([0-9A-Za-z_-]{11})$/;
// Match Youtube links
const linkRE = /((?:www|m)\.)?(?:youtube\.com|youtu.be)/;

export function isDisabledSite(url) {
  if (url.match(idRE) || url.match(linkRE)) {
    return true;
  }

  return false;
}

// Return an RFC5987 encoded string for use in Content-Disposition header
// Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
export function encodeRFC5987ValueChars(str) {
  return (
    encodeURIComponent(str)
      // Note that although RFC3986 reserves "!", RFC5987 does not,
      // so we do not need to escape it
      .replace(/['()]/g, escape) // i.e., %27 %28 %29
      .replace(/\*/g, '%2A')
      // The following are not required for percent-encoding per RFC5987,
      // so we can allow for a little better readability over the wire: |`^
      .replace(/%(?:7C|60|5E)/g, unescape)
  );
}

// RFC5987 encoded filename for use in Content-Disposition header
export function encodeFilename(filename) {
  if (!filename) return '';

  try {
    return encodeRFC5987ValueChars(filename);
  } catch (e) {
    return '';
  }
}

export function getDownloadLink({ filename, hostname }) {
  if (filename && hostname) {
    const host = `${FILES_HOST_PROTOCOL}://${hostname}.${WORKSPACE}.${FILES_DOMAIN_NAME}`;

    return `${host}/${encodeURIComponent(filename)}?filename=${encodeFilename(
      filename
    )}`;
  }

  return null;
}

// Open a URL in a new window every n minutes (Default 10 minutes)
export function openURL(url, everyMinutes = 10) {
  if (!url) return false;

  if (getItem('ourl') === null) {
    // Open a new window with the specified url
    window.open(url, '_blank', 'noopener');

    const expires = everyMinutes * 60 * 1000;

    // Set a localStorage item that expires
    setItem('ourl', 1, expires);

    return true;
  }

  return false;
}
