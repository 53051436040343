import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { sendTask } from 'lib/api';
import Button from 'components/Button';
import useHandleErrors from 'hooks/useHandleErrors';
import useAbortController from 'hooks/useAbortController';

export default function ServerDownloadButton({
  text,
  initialStatus,
  // Default to download task
  convert = false,
  info,
  format,
  quality,
  cutFrom,
  cutTo,
  ...props
}) {
  const { t } = useTranslation();
  text = text ?? t('common:start_dl');

  const [result, setResult] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false);
  const [status, setStatus] = React.useState(initialStatus);
  const { signal } = useAbortController();

  const handleErrors = useHandleErrors();

  function onProgress({ result } = {}) {
    if (result) {
      const status = [];

      // Some tasks have multiple steps to complete
      // We don't want to show the steps if it's only 1
      if (result.step && result.steps && result.steps !== 1) {
        status.push(
          t('common:step_n_of', {
            step: Math.min(result.step, result.steps),
            total: result.steps,
          })
        );
      }

      if (result.progress?.status) {
        status.push(t(`common:status.${result.progress.status}`));
      }

      if (result.progress?.percent) {
        status.push(result.progress?.percent);
      }

      setStatus(status.join(' - '));
    }
  }

  async function startDownload() {
    // Already completed (default link action)
    if (isDone) {
      return true;
    }

    // Prevent multiple clicks
    if (isLoading) {
      return false;
    }

    setIsLoading(true);

    setStatus(t('common:starting'));

    const task = {
      type: convert ? 'convert' : 'download',
      url: info.webpage_url,
      format,
      // Add the quality option for the convert task
      ...(convert && quality !== null && { quality }),
      // Add the cutting options if provided
      ...(typeof cutFrom === 'number' && { from: cutFrom }),
      ...(typeof cutTo === 'number' && { to: cutTo }),
    };

    await sendTask({
      data: task,
      onSuccess: (response) => {
        setIsDone(true);
        setStatus(t('common:finished_click_dl'));
        setResult(response.result);
      },
      onError: (error) => {
        handleErrors(error);
        setStatus(t('common:error_status', { message: error.message }));
      },
      taskOptions: { signal, onProgress },
    });

    setIsLoading(false);
  }

  // Completed file object (We're expecting 1 file)
  const file = result?.[0] ?? {};
  const fileSize = parseInt(file.size, 10) > 0 ? ` (${file.size})` : '';

  return (
    <>
      <Button
        as="a"
        href={file.link}
        disabled={isLoading}
        isLoading={isLoading}
        onClick={startDownload}
        color={isDone ? 'secondary' : 'primary'}
        {...props}
      >
        {isDone
          ? t('common:download_format', {
              format: `${file.ext?.toUpperCase()}${fileSize}`,
            })
          : text}
      </Button>
      {status && <p className="text-sm mt-1 text-gray-700">{status}</p>}
    </>
  );
}
